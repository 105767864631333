import { store } from './utils/store';

export type State = ReturnType<typeof store.getState>;
export type Dispatch = typeof store.dispatch;

export type UpdateReservation = {
  id?: number,
  locationId: number,
  startTime: string,
  endTime: string,
  recurrence: string,
  notification: string,
  noOfRecurrentReservations: number
  }

export type Reservation = {
  id: number,
  chargerId: string,
  socketId: null,
  connectionTime: null,
  startTime: Date,
  endTime: Date,
  actualStartTime: null,
  actualEndTime: null,
  energy: null,
  status: string,
  reference: string,
  locationSummaryDto: Location
  recurrence: RecurrenceType,
  notification: string,
  noOfRecurrentReservations: number
};

export enum RecurrenceType  {
  DOES_NOT_REPEAT = 'Does not repeat',
  EVERY_WEEK = 'Every week',
  EVERY_MONTH = 'Every month'
}

export type Location = {
  id: number,
  name: string,
  address: Address,
  latitude: string,
  longitude: string,
  favorite: boolean,
  totalEvses: number,
  availableEvses: Map<string, number>,
  s3Photo: string | null
};

export type ReservedEvse = {
  locationId: number,
  startTime: string,
  endTime: string,
  excludedReservationId: number
}

export type Address = {
  street: string,
  postalCode: string,
  city: string | null
};

export type Paginated<T> = {
  total: number,
  totalPages: number,
  items: T[]
};

export type StartSession = {
  evseId: string,
  reference: string
}

export type ChargeDetails = {
  sessionStatus: string,
  energy: string,
  connected: string,
  finishes: number,
  startTime: string,
  endTime: string
}

export type AfterChargeDetails = {
  energy: string,
  connected: string,
  startTime: Date,
  endTime: Date
}

export enum SessionStatus {
  Reservation = 'RESERVATION',
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Invalid = 'INVALID'
}
export enum ReservationStatus {
  CHARGING = 'CHARGING',
  CHARGED = 'CHARGED',
  NOT_CHARGED = 'NOT_CHARGED',
  PENDING = 'PENDING',
  TO_BE_CHARGED = 'TO_BE_CHARGED',
  FAILED = 'FAULTY'
}

export const timeoutSeconds = 120;

export type Raw<T> = {
  [K in keyof T]: T[K] extends Date ? string : T[K]
};

export type  DeleteReservationArgs = {
  id: number,
  deleteRec: boolean
}
