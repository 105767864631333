import { DialoogProps } from 'dialoog';
import { useTranslation } from 'react-i18next';

import { ReactComponent as BackIcon } from '../../assets/icons/arrowLeft.svg';
import { intlKeys } from '../../localization-keys';
import { Button } from '../forms/Button';

import styles from './Settings.module.scss';
import { InlineDialog } from './base/InlineDialog';

export function Settings(props: DialoogProps) {
  const { t } = useTranslation();

  return (
    <InlineDialog className={styles.settings} {...props}>
      <div className={styles.header}>
        <button onClick={props.close}>
          <BackIcon className={styles.back}/>
        </button>
        {t(intlKeys.SETTINGS)}
      </div>
      {/* <Setting name="user@email.com" label="Email"/>
      <Setting name="Change password" onClick={console.log}/>
      <Setting name="Language" value="English" onClick={console.log}/> */}
      <Button text={t(intlKeys.LOGOUT)} className={styles.logout} onClick={
        () => {
          localStorage.removeItem('token');
          window.location.pathname = '/';
        }
      }/>
    </InlineDialog>
  );
}
