import { format } from 'date-fns';
import { useDialoog } from 'dialoog';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { intlKeys } from '../../localization-keys';
import { cs } from '../../utils/cs';
import { Select } from '../forms/Select';

import styles from './RecurrenceOption.module.scss';

type Props = {
  date: Date,
};

export function RecurrenceOption ({date}: Props) {
  const { t } = useTranslation();
  const [, { open }] = useDialoog();
  const { setValue, getValues } = useFormContext();
  const [recurrenceDate, setRecurrenceDateEnd] = useState(date);
  const recurrence = getValues('recurrence');

  function getMonthFromString(mon:string){

    var d = Date.parse(mon + '1, 2012');
    if(!isNaN(d)){
      return new Date(d).getMonth() ;
    }
    return -1;
  }

  function parseADate(value: string)
  {
    var year = parseInt(value.split('(')[0].split(' ')[2]);
    var month = getMonthFromString(value.split('(')[0].split(' ')[1]);
    var day = parseInt(value.split('(')[0].split(' ')[0]);
    return new Date(year,month,day );
  }

  const options = recurrence === 'Every week' ? (
    [['0', `${format(new Date(date.getFullYear(), date.getMonth(), date.getDate()+7), 'dd LLLL yyyy')} (1 weeks)`]]
  ) : (
    [['0', `${format(new Date(date.getFullYear(), date.getMonth()+8, date.getDate()), 'dd LLLL yyyy')} (1 months)`]]
  );

  if(recurrence === 'Every week'){
    for(let i= 2; i <= 12; i++){
      let newOption = [];
      let nextWeek = new Date(date.getFullYear(), date.getMonth(), date.getDate()+7*i);
      newOption.push(`${i}`);
      newOption.push(`${`${format(nextWeek, 'dd LLLL yyyy')} (${i} weeks)`}`);
      options.push(newOption);
    }
  }

  if(recurrence === 'Every month'){
    for(let i= 2; i <= 12; i++){
      let newOption = [];
      let nextWeek = new Date(date.getFullYear(), date.getMonth()+8*i, date.getDate());
      newOption.push(`${i}`);
      newOption.push(`${`${format(nextWeek, 'dd LLLL yyyy')} (${i} months)`}`);
      options.push(newOption);
    }
  }

  return(
    <div className={styles.recurrenceContainer}>
      <p>End Repeat</p>
      <div className={styles.endRecurrence}>
        <p 
          className= {cs(styles.toggle, {
            [styles.error]: recurrenceDate === date 
          })}
          onClick={
            open.c((props) => (
              <Select
                title={t(intlKeys.RECURRENCE_END_DATE)}
                options={options}
                setOption={(value) => { setValue('noOfRecurrentReservations', parseInt(value.split('(')[1].split(' ')[0])); 
                  setRecurrenceDateEnd( parseADate(value));                  
                }}
                {...props}
              />
            ))
          }  
        >{recurrenceDate.toDateString()}</p>
      </div>
    </div>
  );
}
