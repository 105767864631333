/*The keys in this file need to match the ones 
//declared in locales translation.json file*/

export const intlKeys ={
  EMAIL: 'EMAIL',
  PASSWORD : 'PASSWORD',
  FORGOT_PWD : 'FORGOT_PWD',
  LOGIN : 'LOGIN',
  LOGOUT : 'LOGOUT',
  INSTALL: 'INSTALL',
  CANCEL: 'CANCEL',
  INSTALL_APP: 'INSTALL_APP',

  NEW_PASSWORD: 'NEW_PASSWORD',
  CONFIRM_PASSWORD: 'CONFIRM_PASSWORD',
  SET_PASSWORD: 'SET_PASSWORD',

  RESERVATIONS : 'RESERVATIONS',
  NO_RESERVATIONS_FOR: 'NO_RESERVATIONS_FOR',
  TAP_ADD: 'TAP_ADD',

  UPCOMING_R: 'UPCOMING_R',
  NO_UPCOMING: 'NO_UPCOMING',

  CHARGING: 'CHARGING',
  CHARGED: 'CHARGED',
  NOT_CHARGED: 'NOT_CHARGED',
  FAILED: 'FAILED',
  CHARGER_ID: 'CHARGER_ID',
  RESERVATION_FUTURE_SUCCESS: 'RESERVATION_FUTURE_SUCCESS',
  RESERVATION_FUTURE_FAIL: 'RESERVATION_FUTURE_FAIL',
  RESERVATION_RECURRING : 'RESERVATION_RECURRING',

  WHERE_TO: 'WHERE_TO',
  FAVORITES: 'FAVORITES',
  RECENTS: 'RECENTS',

  NEW_RESERVATION: 'NEW_RESERVATION',
  UPDATE_RESERVATION: 'UPDATE_RESERVATION',
  GET_DIRECTIONS: 'GET_DIRECTIONS',
  ARRIVAL_TIME: 'ARRIVAL_TIME', 
  DEPARTURE_TIME: 'DEPARTURE_TIME',
  INVALID_TIME: 'INVALID_TIME',
  NO_CONNECTORS_AT_LOCATION: 'NO_CONNECTORS_AT_LOCATION',
  AVAILABLE_CONNECTORS: 'AVAILABLE_CONNECTORS',
  SOMETHING_WRONG: 'SOMETHING_WRONG',
  UPDATE: 'UPDATE',
  RESERVE: 'RESERVE',
  CANCEL_RESERVATION: 'CANCEL_RESERVATION',
  WANT_CANCEL: 'WANT_CANCEL', 
  WRONG_CANCEL: 'WRONG_CANCEL',
  KEEP_RESERVATION: 'KEEP_RESERVATION',
  CANCEL_RECURRENCE: 'CANCEL_RECURRENCE',
  NO_CONNECTORS: 'NO_CONNECTORS',

  RECURRING_RESERVATION: 'RECURRING_RESERVATION',
  RECURRENCE_END_DATE: 'RECURRENCE_END_DATE',
  RECURRENCE_TYPE_DOES_NOT_REPEAT: 'RECURRENCE_TYPE_DOES_NOT_REPEAT',
  RECURRENCE_TYPE_EVERY_WEEK: 'RECURRENCE_TYPE_EVERY_WEEK',
  RECURRENCE_TYPE_EVERY_MONTH: 'RECURRENCE_TYPE_EVERY_MONTH',

  NOTIFICATION_TYPE_NO_ALERT: 'NOTIFICATION_TYPE_NO_ALERT',
  NOTIFICATION_TYPE_10_MINUTES: 'NOTIFICATION_TYPE_10_MINUTES',
  NOTIFICATION_TYPE_30_MINUTES:  'NOTIFICATION_TYPE_30_MINUTES',
  NOTIFICATION_TYPE_1_HOUR:  'NOTIFICATION_TYPE_1_HOUR',

  CHECK_CONNECTOR: 'CHECK_CONNECTOR',

  YOUR_RESERVATION: 'YOUR_RESERVATION',
  LOOK_FOR_LED: 'LOOK_FOR_LED',
  SWIPE_CARD: 'SWIPE_CARD',
  DONT_HAVE_CARD: 'DONT_HAVE_CARD',
  SELECT_CONNECTOR_NO: 'SELECT_CONNECTOR_NO',
  START_CHARGING: 'START_CHARGING',
  STOP_CHARGING: 'STOP_CHARGING',
  STARTING_SESSION: 'STARTING_SESSION',
  STOPPING_SESSION: 'STOPPING_SESSION',  
  IN_MINUTES: 'IN_MINUTES',
  CONNECTED: 'CONNECTED',
  CONNECTOR: 'CONNECTOR',
  ENERGY: 'ENERGY',
  FINISH_AT: 'FINISH_AT',
  FINISHED_AT: 'FINISHED_AT',
  SESSION_COMPLETED: 'SESSION_COMPLETED',
  STARTED_AT: 'STARTED_AT',
  RETURN: 'RETURN', 
  INVALID_SESSION: 'INVALID_SESSION',
  COULD_NOT_STOP_SESSION: 'COULD_NOT_STOP_SESSION',

  TODAY: 'TODAY',

  INVALID_EMAIL : 'INVALID_EMAIL', //'Invalid e-mail',
  CANNOT_BE_EMPTY : 'CANNOT_BE_EMPTY', //'Cannot be empty'
  UNKNOWN_ERROR: 'UNKNOWN_ERROR', //UNKNOWN_ERROR'Unknown error'
  SETTINGS: 'SETTINGS' //Settings
};
