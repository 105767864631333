import { format } from 'date-fns';
import { useDialoog } from 'dialoog';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CalendarIcon } from '../assets/icons/calendar.svg';
import { ReactComponent as PalmIcon } from '../assets/icons/palm.svg';
import { ReactComponent as SettingsIcon } from '../assets/icons/settings.svg';
import { EmptyList } from '../components/EmptyList';
import { Header } from '../components/Header';
import { Spinner } from '../components/Spinner';
import { Settings } from '../components/dialogs/Settings';
import { ReservationCard } from '../components/reservation/ReservationCard';
import { useInfiniteScroll } from '../hooks/useInfiniteScroll';
import { intlKeys } from '../localization-keys';
import { useGetReservationsQuery } from '../services/reservations';
import { groupData } from '../utils/groupData';

import styles from './Upcoming.module.scss';

export function Upcoming() {
  const [t] = useTranslation();
  const [, { open }] = useDialoog();
  const [ref, { loading, items }] = useInfiniteScroll('0px 0px 400px 0px', useGetReservationsQuery);
  const grouped = useMemo(() => groupData(items, 'startTime', (v) => v.toDateString()), [items]);

  return (
    <>
      <Header
        text= {t(intlKeys.UPCOMING_R)}
        actions={[{
          icon: <CalendarIcon/>,
          onClick: '/'
        }, {
          icon: <SettingsIcon/>,
          onClick: open.c((props) => (
            <Settings {...props}/>
          ))
        }]}
      />
      <div className={styles.container}>
        {Object.entries(grouped).map(([group, entries]) => (
          <Fragment key={group}>
            <div className={styles.date}>
              {format(entries[0].startTime, 'dd MMMM yyyy')}
            </div>
            {entries.map((reservation, i) => (
              <ReservationCard
                connectedTime={''}
                key={i}
                reservation={reservation}
                onClick={console.log}
              />
            ))}
          </Fragment>
        )) || (
          <EmptyList icon={<PalmIcon/>}>
            {t(intlKeys.NO_UPCOMING)}<br/>{t(intlKeys.TAP_ADD)}
          </EmptyList>
        )}
        {loading && (
          <div className={styles.spinner} ref={ref}>
            <Spinner/>
          </div>
        )}
      </div>
    </>
  );
}
