import { useEffect, useState } from 'react';
import useScroll from 'react-infinite-scroll-hook';

import { Paginated } from '../types';

type Hook<T> = {
  data?: Paginated<T>,
  isFetching: boolean,
  error?: any
};

export function useInfiniteScroll<T>(margin: string, hook: (args: { page: number }) => Hook<T>) {
  const [items, setItems] = useState<T[]>([]);
  const [total, setTotal] = useState(1);
  const [page, setPage] = useState(0);
  const { data, isFetching: loading, error } = hook({ page });
  const [ref] = useScroll({
    loading,
    hasNextPage: items.length < total && !error,
    onLoadMore: () => setPage(page + 1),
    rootMargin: margin
  });

  useEffect(() => {
    if (data) {
      setItems((i) => [...i, ...data.items]);
      setTotal(data.total);
    }
  }, [data, error]);

  return [ref, { loading, error, items }] as const;
}
